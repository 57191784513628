<template>
  <div class="page-my-app">
    <n-loader :loading="$var('load')" />
    <top-bar back :backroute="type === 'inactive' ? 'profile' : 'index'" title="Моя заявка" />
    <div class="container">
      <div class="wrap">
        <div class="image-place">
          <div class="image">
            <img :src="application.image" alt="">
          </div>
        </div>
        <div class="text">
          <div class="left-side">
            <div class="title">{{ application.name }}</div>
          </div>
          <div class="right-side">
          </div>
        </div>
        <div class="text">
          <div class="left-side">
            <n-icon icon="date" />
          </div>
          <div class="right-side">
            {{ $app.date.format(application.createdAt, 'date') }}
          </div>
        </div>
        <div class="text">
          <div class="left-side">
            <n-icon icon="volume" />
          </div>
          <div class="right-side">
            {{ application.volume }}, {{ types[application.volumeType] }}
          </div>
        </div>
        <div class="text">
          <div class="left-side">
            <n-icon icon="author" />
          </div>
          <div class="right-side">
            {{ application.author?.name }}
          </div>
        </div>
        <div class="text">
          <div class="left-side">
            <n-icon icon="address" />
          </div>
          <div class="right-side">
            {{ application.address }}
          </div>
        </div>
        <!--        <div class="doc-btn">-->
        <!--          <n-icon icon="applications" />-->
        <!--          <div class="btn-text">Акт приема передачи</div>-->
        <!--        </div>-->
        <!--        <div class="doc-btn">-->
        <!--          <n-icon icon="applications" />-->
        <!--          <div class="btn-text">Акт утилизации</div>-->
        <!--        </div>-->
        <n-button v-if="type === 'inactive'" color="yellow" wide @click="confirm">Подтвердить</n-button>
        <n-button v-if="user.isAdmin && !application.shopAccepted && application.isActive"
                  color="yellow" wide @click="shopAccept">
          Подтвердить завершение
        </n-button>
        <n-button v-if="!user.isAdmin && !application.userAccepted && application.isActive"
                  color="yellow" wide @click="userAccept">
          Подтвердить завершение
        </n-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PageMyApp',
  data() {
    return {
      application: {},
      user: $app.auth.user(),
      types: {
        kg: 'Кг',
        tonn: 'Тонн',
        cubes: 'Кубов',
        litres: 'Литров',
        pieces: 'Штук',
        other: 'Другое',
      },
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    type() {
      return this.$route.params.type
    },
  },
  created() {
    this.load()
  },
  methods: {
    shopAccept() {
      this.$var('load', true)
      $api.application.edit(this.id, { shopAccepted: true, }).then(() => {
        this.$router.push({ name: 'orders', })
      }).finally(() => {
        this.$var('load', false)
      })
    },
    userAccept() {
      this.$var('load', true)
      $api.application.edit(this.id, { userAccepted: true, }).then(() => {
        this.$router.push({ name: 'orders', })
      }).finally(() => {
        this.$var('load', false)
      })
    },
    confirm() {
      this.$var('load', true)
      $api.application.confirm(this.id).then(() => {
        this.$router.push({ name: 'profile', })
      }).finally(() => {
        this.$var('load', false)
      })
    },
    load() {
      this.$var('load', true)
      $api.application.get(this.id).with('author').then((response) => {
        this.application = response.data.content
        console.log(this.application)
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.page-my-app {
  .image-place {
    display: flex;
    align-items: center;
    justify-content: center;
    .image {
      width: 100vw;
      height: 250px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .doc-btn {
    display: flex;
    align-items: center;
    min-height: 60px;
    background-color: #F5F5F5;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 15px;
    .btn-text {
      margin-left: 15px;
      font-size: 16px;
    }
  }
  .wrap {
    width: 100%;
    .title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 15px;
    }
    .text {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      .left-side {
        margin-right: 15px;
      }
    }
  }
}
</style>
